<template>
    <div class="mt-3 px-xl-5">

        <b-row class="justify-content-end mb-3">
            <b-col v-if="allowRoles" class="text-end">
                <b-button class="main-btn" @click="goToSendInvitation">
                    <b-icon-person-plus-fill scale="1.2" />
                </b-button>
            </b-col>
            <b-col xl="3" md="6">
                <b-input-group class="mt-1">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                        @keydown.enter="filterTable"></b-form-input>
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm" @click="filterTable">
                            <b-icon-search />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table ref="officialsTable" stacked="md" small hover show-empty
                        empty-text="No hay funcionarios que mostrar" :filter="filter" :busy.sync="isBusy" :fields="fields"
                        :items="retrieveData" :current-page="currentPage" :per-page="take" @filtered="onFiltered">
                        <template #cell(used)="data">
                            <div>
                                {{ data.item.used ? "Sí" : "No" }}
                            </div>
                        </template>
                        <template #cell(disable)="data">
                            <div>
                                <b-button :disabled="!allowDisable(data.item)" @click="openDisableOfficial(data.item)"
                                    :variant="data.item.isEnabled ? 'success' : 'danger'">
                                    <b-icon icon="x-circle" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                    class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3" /></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3" /></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3" /></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3" /></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
        <DisableOfficialModal :isOpen="openedDisable" :id="officialId" @close="closeDisable" @disabled="refreshTable" />
    </div>
</template>

<script>
import moment from "moment";

import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";

import DisableOfficialModal from "./DisableOfficialModal.vue";

export default {
    name: "officials-list",
    components: {
        DisableOfficialModal,
    },
    data() {
        return {
            fields: [
                { key: "email", label: "Email" },
                { key: "creationDate", label: "Fecha" },
                { key: "used", label: "Usado" },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,

            officialId: null,
            openedDisable: false,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCreateCA",
            "allowOfficialCreateAndAsignRoles",
        ]),
        ...mapGetters("application", ["isSuperUser"]),
        allowRoles() {
            return (
                this.allowSuperintendenceCreateCA ||
                this.allowOfficialCreateAndAsignRoles
            );
        },
    },
    methods: {
        allowDisable(official) {
            return official.isEnabled && this.isSuperUser;
        },
        openDisableOfficial(official) {
            this.openedDisable = true;
            this.officialId = official.id;
        },
        closeDisable() {
            this.openedDisable = false;
        },
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                let response = await RepositoryFactory.official.getInvitation(
                    payload
                );

                this.rows = response.data.total;
                let items = response.data.items;

                for (const item of items) {
                    item.creationDate = this.parseDate(item.creationDate);
                    item.identificationType = this.getIdentificationTypeString(
                        item.identificationType
                    );
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY hh:mm A");
        },
        goToSendInvitation() {
            this.$router.push({
                name: "send-official-invitation",
            });
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "-";
        },
        refreshTable() {
            this.$refs.officialsTable.refresh();
        },
    },
};
</script>