<template>
    <b-modal
        v-model="isOpen"
        content-class="rounded-0"
        dialog-class="long-modal"
        header-class="border-0"
        header-bg-variant="light"
        centered
        no-close-on-backdrop
        hide-footer
    >
        <template #modal-header>
            <div class="modal-custom-title">
                <h5 class="modal-title">Desactivar Funcionario</h5>
                <b-button
                    size="sm"
                    class="close-modal-button"
                    variant="light"
                    @click="close"
                >
                    <b-icon-x scale="1.5" />
                </b-button>
            </div>
        </template>
        <b-row v-if="isError" class="p-2">
            <b-col class="text-center">
                <b-icon-x-circle scale="2" variant="danger" />
                <h6 class="mt-3">
                    Se ha producido un error intentando deshabilitar el
                    funcionario, recargue la ventana y vuelva a intentarlo.
                </h6>
                <b-button class="main-btn mt-2" @click="close">
                    Aceptar
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="isSuccess" class="p-2">
            <b-col class="text-center">
                <b-icon-shield-check scale="2" variant="success" />
                <h6 class="mt-3">
                    Se ha deshabilitado correctamente el funcionario
                </h6>
                <b-button class="main-btn mt-2" @click="close">
                    Aceptar
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="!isError && !isSuccess">
            <b-col class="text-center">
                <h6 class="mt-3">
                    Se va a deshabilitar este funcionario, esto impedirá al
                    usuario acceder al sistema. Esta acción es irreversible, por
                    favor confirme que quiere deshabilitar este funcionario.
                </h6>
                <b-overlay
                    :show="sending"
                    rounded
                    opacity="0.7"
                    spinner-small
                    spinner-variant="danger"
                    class="d-inline-block"
                >
                    <b-button
                        class="my-4"
                        variant="danger"
                        @click="disableOfficial"
                    >
                        Deshabilitar
                    </b-button>
                </b-overlay>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

export default {
    name: "disable-resident-agent-modal",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
        },
    },
    data() {
        return {
            sending: false,
            isError: false,
            isSuccess: false,
        };
    },
    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.isError = false;
                this.isSuccess = false;
            }
        },
    },
    methods: {
        async disableOfficial() {
            this.sending = true;
            try {
                await RepositoryFactory.official.disable({
                    id: this.id,
                });
                this.$emit("disabled");
                this.isSuccess = true;
            } catch (error) {
                window.console.log(error);
                this.isError = true;
            }
            this.sending = false;
        },
        close() {
            this.$emit("close");
        },
        hideError() {
            this.isError = false;
        },
    },
};
</script>

<style scoped>
.modal-custom-title {
    position: relative;
    width: 100%;
}

.close-modal-button {
    position: absolute;
    top: 0px;
    right: 0px;
}
</style>