<template>
    <div class="mt-3 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            {{ $t("navbar.officials") }}
        </h6>
        <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
            <b-tab :title="$t('officials.title')" title-item-class="dash-tab" @click="changeTab(0)">
                <OfficialsList />
            </b-tab>
            <b-tab v-if="allowCurManagement" :title="$t('officials.invitations')" title-item-class="dash-tab"
                @click="changeTab(1)">
                <OfficialInvitationsList />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import OfficialsList from "./OfficialsList.vue";
import OfficialInvitationsList from "./OfficialInvitationsList.vue";

export default {
    name: "law-firms",
    components: {
        OfficialInvitationsList,
        OfficialsList
    },
    created() {
        if (this.$route.params.tab) {
            this.tabIndex = this.$route.params.tab;
        }
    },
    data() {
        return {
            tabIndex: 0,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCurManagement",
            "allowSuperintendenceConfigureCriteria",
        ]),
        allowCurManagement() {
            return this.allowSuperintendenceCurManagement;
        },
        allowCriteria() {
            return this.allowSuperintendenceConfigureCriteria;
        },
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index;
        },
    },
};
</script>
